.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000  !important;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 80%;
  max-width: 600px;
  height: 100vh;
}

.closeContainer {
  width: 100%;
  display: flex;
  justify-content: end;
}

.close {
  top: 10px;
  right: 15px;
  font-size: 2rem;
  color: #777272;
  cursor: pointer;
  border: none;
  background-color: white;
}

.close:hover {
  text-decoration: underline;
}

.password {
  width: 60%;
  margin: 20% auto;
}

.currentPassword1 {
  margin-bottom: 30px;
}

.passwordHeader {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 300;
}

.currentPasswordP {
  color: rgb(126, 122, 122);
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.passwordField {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  /* Changed to remove border-radius on bottom */
  font-size: 1rem;
  border: 2px solid lightgray;
  box-sizing: border-box;
}

.passwordField:focus {
  outline: none;
  border-color: #0070ba;
  box-shadow: 0 0 5px #0070ba;
}


.passwordField.error {
  border-color: red;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.passwordError {
  margin: 0;
  border: 2px solid red;
  padding: 4px;
  border-top: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(245, 106, 106, 0.1);
  border-radius: 0 0 5px 5px;
  /* Changed to remove border-radius on top */
}


.btn {
  width: 100%;
  padding: 8px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid #0070ba;
  background-color: #0070ba;
  color: white;
  font-weight: 500;
  box-sizing: border-box;
}

.btn:hover {

  background-color: #0070ba;
}

.btnT:hover {
  text-decoration: underline;
}

.changePassword1,
.changePassword2 {
  margin-bottom: 20px;
}

.logoContainer {
  width: 100%;
  display: flex;
  justify-content: center;

}

@media (max-width: 641px) {
  .modal-content {
    width: 100%;
  }

  .password {
    width: 100%;
  }
}
