.sendMoney3 {
  margin-top: 9rem;
  left: 0;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contactInput {
  width: 80%;
  border: none;
  border-radius: 1.6rem;
  padding: 0.8rem 1.3rem;
  font-size: 1.1rem;
  background-color: white;
  border: 1px solid rgb(226, 219, 219);
  margin-right: auto;
  margin-left: auto;
}

.contactInput:focus {
  outline: none;
  border: 1px solid #046096;
  box-shadow: 0px 0px 5px #0079c1;
}

.view {
  color: #0070ba;
  font-weight: 500;
  margin-right: auto;
  margin-left: auto;
}

.view:hover {
  color: #0070ba;
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .sendMoney3 {
    width: 90%;
  }

  .contactInput {
    width: 100%;
  }
}