.sendMoney1 {
  margin-top: 9rem;
  left: 0;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.payment1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
}

.payment1 > P {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.sendToInput {
  width: 100%;
  border: none;
  border-radius: 1.6rem;
  padding: 0.8rem 1.3rem;
  font-size: 1.1rem;
  background-color: white;
  border: 1px solid rgb(226, 219, 219);
}

.sendToInput:focus {
  outline: none;
  border: 1px solid #046096;
  box-shadow: 0px 0px 5px #0079c1;
}
.contactAbc {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px; 
  transition: background-color 0.3s ease;
  background-color: #f5f5f5; /* Light grey background color */
  z-index: 10; /* Increase z-index to make the contact appear above other elements */
}

.contactAbc:hover {
  background-color: #ebebeb; /* Change the background color when hovering */
}

.selectedAbc {
  background-color: #b8d8d8; /* Distinct color for selected contact */
  z-index: 20; /* Increase z-index even more for selected contacts */
}

.userNotFound {
  color: red;
  font-weight: bold;
  z-index: 1; 
}


#btn1 {
  width: 17%;
  padding: 1% 2%;
  border-radius: 20px;
}

.moreWays1 {
  gap: 20px;
  width: 28%;
}

.moreWays1P {
  font-size: 20px;
  font-weight: 500;
}

.invoice1 {
  display: flex;
}

.invoice1:hover {
  color: black;
}

.waysIcon1 {
  height: 25px;
  width: 25px;
  background-color: rgb(240, 235, 235);
  border-radius: 50%;
  padding: 3px;
  color: #0070ba;
  margin-right: 5px;
  margin-top: 2px;
}

.div1 {
  gap: 0;
}

.p1 {
  margin-bottom: 0;
  font-weight: 600;
}

.p2 {
  font-size: 12px;
  font-weight: 300;
  margin-top: 0;
  line-height: 13px;
}

@media screen and (max-width: 1024px) {
  .sendMoney1 {
    flex-direction: column;
    gap: 30px;
    width: 90%;
  }

  .payment1, .moreWays1 {
    width: 100%;
  }

  .payment1 > #btn1 {
    width: 24%;
    padding: 1% 2%;
    border-radius: 20px;
  }
}

.selectedContact {
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 4px;
  margin-right: 4px;
}

.contactName {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.userIcon {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.clearIcon {
  cursor: pointer;
}

