.settingsNav {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  margin: 0 auto;
}

/* Apply margin only on larger devices */
@media (min-width: 768px) {
  .settingsNav {
    margin-top: 20px;
  }
}

.scrollContainer {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}

.account {
  text-decoration: none;
  font-size: 17px;
  font-weight: 400;
  color: black;
  border: 1px solid white;
  padding: 10px 12px;
  border-radius: 24px;
}

.account:hover {
  border: 1px solid #0070ba;
  font-weight: 500;
  color: #0070ba;
}

.active {
  background-color: #d7e6f0;
  color: #0070ba;
}

@media (max-width: 768px) {
  .settingsNav {
    overflow: auto;
    white-space: nowrap;
    margin-top: 0; 
    padding: 10px 0;
    margin-bottom: 1em;
  }

  .scrollContainer {
    padding: 0 10px; 
  }

  .account {
    font-size: 16px; 
    padding: 8px 10px;
  }
}
