.background-opacity::before {
  border-radius: 1.25rem;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://img.freepik.com/free-vector/illustration-diverse-people_53876-28662.jpg');
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  opacity: 2.5;
  filter: blur(1px);
  z-index: -1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


