.modal2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000  !important;
}

.modal-content2 {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 80%;
  max-width: 600px;
  height: 100vh;
}

.closeContainer2 {
  width: 100%;
  display: flex;
  justify-content: end;
}

.password2 {
  width: 60%;
  margin: 20% auto;
}

.close2 {
  top: 10px;
  right: 15px;
  font-size: 2rem;
  color: #777272;
  cursor: pointer;
  border: none;
  background-color: white;
}

.close2:hover {
  text-decoration: underline;
}

.passwordHeader2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
}

.addition1 {
  text-align: center;
  color: #5c5a5a;
  font-size: 0.9rem;
  margin-bottom: 40px;
}

.btn2 {
  width: 100%;
  padding: 12px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid #0070ba;
  background-color: #0070ba;
  color: white;
  font-weight: 500;
  box-sizing: border-box;
}

.btn2:hover {
  background-color: #0070ba;
}

.logoContainer2 {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 641px) {
  .modal-content2 {
    width: 100%;
  }

  .password2 {
    width: 100%;
  }
}
