.modalChoose {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
}

.modal-contentChoose {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  width: 80%;
  max-width: 600px;
  height: auto;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.closeContainerChoose {
  width: 100%;
  display: flex;
  justify-content: end;
}

.closeChoose {
  top: 10px;
  right: 15px;
  font-size: 2rem;
  color: #777272;
  cursor: pointer;
  border: none;
  background-color: white;
}

.closeChoose:hover {
  color: #0070ba;
}

.chooseImg {
  margin: auto;
  margin-bottom: 20px;
}

.modalContentChooseDiv {
  width: 60%;
  margin: auto;
}

.choosePreference {
  color: black;
  font-size: 30px;
  font-weight: 200;
  margin-bottom: 16px;
  line-height: 1.3;
}

.goodsAndServices {
  font-size: 14px;
  color: #6c7378;
  margin: 16px 0;
  text-align: center;
}

.morePreferences {
  color: #0070ba;
  font-size: 14px;
  font-weight: 600;
}

.morePreferences:hover {
  text-decoration: underline;
}

.radio-preferred {
  border-top: 1px dotted lightgray;
  border-bottom: 1px dotted lightgray;
  margin: 20px 0;
  padding: 10px 0;
}

.chooseImg1 {
  width: 15px;
  margin: 0 8px;
}

.errorPreferred {
  color: red;
  margin: 20px 0;
}

.btnChoose {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #0070ba;
  background-color: #0070ba;
  color: white;
  font-weight: 500;
  box-sizing: border-box;
}

.btnChoose:hover {
  background-color: #005a9c;
}

@media (max-width: 641px) {
  .modal-contentChoose {
    width: 100%;
  }

  .modalContentChooseDiv {
    width: 80%;
  }
}
