.settingsNav {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  margin: 0 auto;
  background-color: #f8f9fa; /* light background for better distinction */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05); /* subtle shadow for elevation */
}

@media (min-width: 768px) {
  .settingsNav {
    margin-top: 20px;
  }
}

.scrollContainer {
  display: flex;
  gap: 16px; /* Increase space between the navigation links */
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}


.scrollContainer::-webkit-scrollbar {
  display: none;
}

.account {
  text-decoration: none;
  font-size: 17px;
  font-weight: 500; /* make the font slightly bolder */
  color: #333; /* darken the text for better contrast */
  border: 1px solid transparent;
  padding: 10px 20px; /* increase padding for better aesthetics */
  border-radius: 24px;
  transition: all 0.3s ease; /* smooth transition for hover effects */
}

.account:hover, .account:focus, .active {
  border: 1px solid #0070ba;
  font-weight: 600;
  color: #0070ba;
  background-color: #eef5fa;
  border-radius: 24px;
}


.active {
  background-color: #d7e6f0;
  color: #0070ba;
  font-weight: 600; 
  border: 1px solid #0070ba; 
  padding: 10px 20px; 
}


@media (max-width: 768px) {
  .settingsNav {
    overflow: auto;
    white-space: nowrap;
    margin-top: 0; 
    padding: 10px 0;
    margin-bottom: 1em;
  }

  .scrollContainer {
    padding: 0 10px; 
  }

  .account {
    font-size: 16px; 
    padding: 6px 7px; /* proportionate padding for smaller devices */
  }
}


.pulseArrow {
  display: none; /* Hide by default */
  position: absolute;
  bottom: 1px;
  right: 10px;
  font-size: 24px;
  color: #0070ba; 
  animation: pulseAnimation 1.5s infinite;
  z-index: 3;
  font-weight: 700;
}

/* Only show on smaller devices */
@media (max-width: 768px) {
  .settingsNav {
    position: relative; /* So that the arrow can be absolutely positioned within it */
    /* ... other styles ... */
  }

  .pulseArrow {
    display: block; /* Show on smaller devices */
  }

  @keyframes pulseAnimation {
    0% {
      opacity: 0.8;
      transform: scale(0.95);
    }
    50% {
      opacity: 1; 
      transform: scale(1);
    }
    100% {
      opacity: 0.8;
      transform: scale(0.95);
    }
  }
}
