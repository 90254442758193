.sendMoney4 {
  margin-top: 9rem;
  left: 0;
  width: 22%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border: 1px solid gray;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
}

.waysIcon4 {
  height: 40px;
  width: 40px;
  background-color: rgb(240, 235, 235);
  border-radius: 50%;
  padding: 7px;
  color: #0070ba;
}

.p4, .p5 {
  margin: 0;
}

.p4 {
  font-weight: 600;
}

.p5 {
  font-size: 13px;
}

#btn4 {
  background-color: #0070ba;
  color: white;
  border: none;
  border-radius: 22px;
  width: 80%;
  padding: 5px;
}

@media screen and (max-width: 1024px) {
  .sendMoney4 {
    width: 90%;
  }
}