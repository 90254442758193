.user-details-container {
    background-color: #f7f8fa; /* General background color */
    padding: 20px; /* Space around cards */
}

.user-details-container .shadow {
    border-radius: 10px; /* Rounded borders for the cards */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Slightly enhanced shadow for a floating effect */
}

.user-details-container p {
    font-size: 16px; /* Making text a bit larger */
    margin-bottom: 16px; /* Increased spacing for better readability */
}

.user-details-container strong {
    font-weight: 500; /* Making the bold text slightly less bold for a modern feel */
}

.user-details-container .fa-envelope,
.user-details-container .fa-phone,
.user-details-container .fa-calendar,
.user-details-container .fa-map-marker {
    color: #007bff; /* Making the icons match the primary color scheme */
    margin-right: 8px; /* Adjusting space after the icon */
}

/* Card styles */
.card.mb-4.shadow {
    border-radius: 10px; /* Rounded borders for modern look */
}

/* Card Header */
.card-header.bg-primary {
    font-size: 1.25rem;  /* Slightly larger header font */
    font-weight: 500;    /* Semi-bold weight */
    border-radius: 9px 9px 0 0; /* Consistent rounding with card border */
}

/* Main Content Styles */
.card-body {
    font-size: 1rem; /* Base font size for card content */
    color: #333;     /* Slightly darker font color for better contrast */
}

.card-body p {
    margin-bottom: 0.8rem;  /* Consistent bottom margin for each item */
}

/* Icons */
.card-body .fa-envelope,
.card-body .fa-phone,
.card-body .fa-calendar,
.card-body .fa-map-marker {
    color: #007bff;       /* Icon color matching primary */
    margin-right: 0.5rem; /* Space after the icon */
}

/* Strong element for emphasis */
.card-body p strong {
    font-weight: 500; /* Medium weight for emphasized content */
}

/* Button */
.card-body .btn-outline-primary {
    font-size: 1rem;      /* Match button font size to content */
    font-weight: 500;     /* Medium font weight */
    padding: 0.5rem 1rem; /* Adjusted padding for button */
}
