.walletMain {
  padding: 20px;
}

.walletContainer {
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin: auto;
  border-radius: 8px;
  padding: 20px;
}

.walletContainerDiv1 {
  width: 40%;
}

.linkCard {
  padding: 10px;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  width: 40%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000 !important;
  pointer-events: none;
}

.linkCardIconP {
  font-size: 30px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(235, 230, 230);
  margin: auto;
  margin-bottom: 5px;
}

.linkCardIcon {
  margin: auto;
  color: #0070ba;
}

.linkCardP {
  font-size: 16px;
  text-align: center;
}

.paypalBal {
  border: 2px solid #0070ba;
  border-radius: 7px;
  display: flex;
  padding: 15px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.paypalBalImg {
  width: 40px;
  border: 1px solid rgb(196, 191, 191);
  border-radius: 4px;
  padding: 3px 10px;
  background-color: rgb(241, 235, 235);
}

@media (max-width: 640px) {
  .paypalBal {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .walletContainingCurrencyImg {
    margin-left: auto;
    margin-right: auto;
  }
}

/* .paypalBalCard {} */

.paypalBalCardP {
  font-size: 16px;
  color: #0070ba;
  font-weight: 600;
  margin: 0;
}

.paypalBalCardAvailable {
  color: black;
  margin: 0;
}

.walletContainerDiv2 {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
}

@media (min-width: 992px) {
  .walletContainerDiv2 {
    margin-top: 9em; /* Add margin to the top of the div on large devices */
  }
}

.walletContainerDiv2Img {
  width: 80px;
  border: 1px solid rgb(196, 191, 191);
  border-radius: 5px;
  padding: 4px 24px;
  background-color: rgb(241, 235, 235);
}

.walletContainerDiv2BalP {
  color: black;
  text-align: center;
  font-size: 16px;
}

.primaryAccountBalance {
  text-align: center;
  font-size: 16px;
  text-align: center;
  color: black;
  font-weight: 600;
}

.walletContainerDiv2Bal {
  text-align: center;
  color: black;
  font-size: 30px;
  font-weight: 600;
}

.walletContainerDiv2P2 {
  color: black;
  text-align: center;
  font-size: 16px;
}

.walletContainerDiv2Btn {
  
  padding: 8px;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid #0070ba;
  background-color: #0070ba;
  color: white;
  font-weight: 500;
  align-items: center;
}

.walletContainingCurrency {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
  margin: 10px auto;
}

.walletContainingCurrencyDiv {
  display: flex;
  align-items: center;
}

.walletContainingCurrencyDiv1 {
  display: flex;
  gap: 10px;
  margin-left: 10px;
}

.walletContainingCurrencyImg {
  margin-top: auto;
  margin-bottom: auto;
}

.walletContainingCurrencyP,
.walletContainingCurrencyP1,
.walletContainingCurrencyBal {
  font-size: 12px;
}

.walletContainingCurrencyP1 {
  border: 1px solid #0070ba;
  height: 18px;
  padding: 0 2px;
  border-radius: 3px;
  color: #0070ba;
}

.walletContainingCurrencyP2 {
  margin-left: 8px;
}

.div9 {
  display: flex;
  position: relative;
}

.div10 {
  text-align: right;
}

.div9Icon {
  margin: auto 0 auto 5px;
  cursor: pointer;
}

.popupMenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 1000;
  width: 130px;
  box-shadow: 1px 1px 1px 1px rgb(189, 182, 182);
}

.popupLink {
  color: black;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.walletContainerDiv2Btn:hover {
  border: 1px solid #0070ba;
  background-color: #0070ba;
}

.addCurrency {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.addCurrencyLink1,
.addCurrencyLink2 {
  color: #0070ba;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

.addCurrencyLink2 {
  margin-left: 3em;
}

.addCurrencyLink1:hover,
.addCurrencyLink2:hover {
  text-decoration: underline;
}

.preferred {
  text-align: center;
  margin-top: 10px;
}

.preferredOnline {
  color: black;
  font-size: 16px;
}

.setPreferred {
  color: #0070ba;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.setPreferred:hover {
  text-decoration: underline;
}

.preferredP1 {
  color: black;
}

.preferredP2 {
  color: black;
}

.preferredAbout {
  color: #0070ba;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
}

.preferredAbout:hover {
  text-decoration: underline;
}

.preferredAbout:focus,
.setPreferred:focus {
  border: 2px solid #0070ba;
  border-radius: 5px;
  padding: 3px;
}
.primarystatus,
.activestatus,
.inactivestatus {
  border: 1px solid #0070ba;
  height: px;
  padding: 0 2px;
  border-radius: 3px;
  color: #0070ba;
}
.primarystatus {
  color: #0070ba;
  border: 1px solid #0070ba;
}

.activestatus {
  color: #4caf50;
  border: 1px solid #4caf50;
}

.inactivestatus {
  color: #f44336;
  border: 1px solid #f44336;
}

@media (max-width: 768px) {
  .walletMain {
    padding: 10px;
  }

  .walletContainer {
    display: block;
    width: 100%;
    margin: auto;
    padding: 3%;
  }

  .walletContainerDiv1 {
    width: 100%;
    display: none;
    margin-bottom: 20px;
  }

  .walletContainerDiv2 {
    width: 100%;
  }
}
.walletContainingCurrencyImg {
  width: 30px; /* Set the width of the image */
  height: 30px; /* Set the height of the image */
  border-radius: 50%; /* Make the image rounded */
}
@media (max-width: 768px) {
  .walletContainingCurrencyImg {
    width: 20px; /* Set the width of the image for small devices */
    height: 20px; /* Set the height of the image for small devices */
  }
}
@media (min-width: 992px) {
  .hide-lg {
    display: none;
    text-align: center;
  }
}
