/* Import other stylesheets if needed */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* Add any container-specific styles */
}

.errorMessage {
    margin-top: 20px;
    color: red;
    /* Add any error message styles */
}

.bonusForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border: 1px solid #d3d3d3;
    padding: 20px;
    border-radius: 8px;
    width: 60%;
    max-width: 400px;
    /* Add form-specific styles */
}

.inputLabel {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* Add label-specific styles */
}

.textInput {
    padding: 10px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    width: 100%;
    /* Add input-specific styles */
}
.successMessage {
    margin-top: 20px;
    color: green;
    /* Add any success message styles */
  }
  
  .errorMessage {
    margin-top: 20px;
    color: red;
    /* Add any error message styles */
  }
.submitButton {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    /* Add button-specific styles */
}

.disabledButton {
    opacity: 0.5;
    cursor: not-allowed;
    /* Add disabled button styles */
}

.submitButton:hover {
    background-color: #45a049;
    /* Add button hover styles */
}

/* Responsive styles */
@media (max-width: 600px) {
    .container {
        padding: 20px 0;
        /* Add responsive container styles */
    }

    .bonusForm {
        width: 90%;
        /* Add responsive form styles */
    }

    .inputLabel, .textInput, .submitButton, .errorMessage {
        font-size: 14px;
        /* Add responsive font size adjustment */
    }
}

