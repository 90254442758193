.modalMakePrimary {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(228, 224, 224);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-contentMakePrimary {
  background-color: white;
  padding: 20px;

  width: 80%;
  max-width: 600px;
  height: 100vh;
}

.closeContainerMakePrimary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.closeMakePrimary {
  font-size: 25px;
  color: #777272;
  cursor: pointer;
  border: none;
  background-color: white;
}

.closeMakePrimary:hover {
  color: blue;
}

.makePrimaryImg {
  height: 30px;
}

.currencyImg {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

.modalContentMakePrimaryDiv {
  width: 60%;
  margin: auto;
}

.euro {
  margin: 20px 0;
}

.euroImg {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px;
}

.euroP {
  text-align: center;
}

.makePrimarytitle {
  font-size: 25px;
  font-weight: 400;
  color: black;
  margin: 10px 0;
  text-align: center;
  line-height: 1.2;
  text-decoration: none;
}

.makePrimaryparagrapgh {
  font-size: 13px;
  text-align: center;
  margin: 20px 0;
}

.btnMakePrimary {
  width: 100%;
  padding: 8px;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid rgb(7, 7, 145);
  background-color: rgb(7, 7, 145);
  color: white;
  font-weight: 500;
  box-sizing: border-box;
}

.btnMakePrimary:hover {
  background-color: rgb(11, 11, 107);
  text-decoration: underline;
}

@media (max-width: 641px) {
  .modal-contentMakePrimary {
    width: 100%;
  }

  .modalContentMakePrimary {
    width: 90%;
    padding: 10px;
  }
}
