@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.card {
  min-width: 100%;
  word-wrap: break-word;
}

.settings,
.settings div {
  max-width: 100%;
  word-wrap: break-word;
}

@keyframes dots {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  67% {
    content: "...";
  }
}

.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.has-error .form-control {
  border-color: #dc3545;
}

.invalid-feedback {
  display: block;
  color: #dc3545;
  font-size: 0.875rem;
}
.success-container {
  height: 100vh;
  flex-direction: column;
}

.success-muted {
  color: #0c5460;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.container {
  padding-bottom: 60px;
  padding-top: 60px;
}

.dashboard-footer {
  display: none;
}

@media (max-width: 767px) {
  .container {
    padding-bottom: 67px;
  }

  .dashboard-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #ffffff;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  .dashboard-footer i {
    font-size: 24px;
    color: #0070ba;
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.loader {
  display: flex;
}

.loader div {
  width: 3px;
  height: 24px;
  background-color: #32cd32;
  margin: 0 3px;
  animation: scaleUpDown 1s infinite ease-in-out;
}

.loader div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes scaleUpDown {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.5);
  }
}


.hotspot-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: rgb(234, 85, 85); /* Choose an attention-grabbing color */
  border-radius: 50%;
  position: relative;
}

.hotspot-indicator::before, .hotspot-indicator::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: spreadSignal 2s ease-out infinite;
}

@keyframes spreadSignal {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
  }
}

.hotspot-indicator::after {
  animation-delay: 1s; /* Delay for a continuous effect */
}

.main-ticket {
  position: relative;
  /* ... other styles ... */
}

.main-ticket::before, 
.main-ticket::after {
  content: '';
  position: absolute;
  left: 50%;
  width: 20px;
  height: 20px;
  background-color: #f0fdf4; /* This color should be the same as the background of the container the card is in */
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.main-ticket::before {
  top: -10px;
}

.main-ticket::after {
  bottom: -10px;
}



/* Add this in your global CSS file */
@keyframes spin {
  to { transform: rotate(360deg); }
}

.circle-timer {
  width: 50px;
  height: 50px;
  border-radius: 9999px; /* Full circle */
  border: 3px solid transparent;
  border-top-color: green;
  position: relative;
  animation: spin 5s linear infinite;
}

.circle-timer::before {
  content: '';
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  border-radius: 9999px; /* Full circle */
  background: white;
  z-index: 1;
}

.circle-timer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
