.main {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  padding: 20px 20%;
}

.profile,
.address {
  width: 49%;
  
}

.profileDetails {
  border: 1px solid rgb(228, 225, 225);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #a1a4a7;
}

.joined {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 20px;
  background-color: #0070ba;
  border-radius: 5px 5px 0 0;
}

.dateJoined {
  display: flex;
  justify-content: space-between;
}

.dateJoined1 {
  color: white;
  font-size: 1.5rem;
}

.dateJoined2 {
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.8rem;
}

.userImage {
  border: 1px solid gray;
  width: 60px;
  height: 60px;
  padding: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  margin-bottom: -40px;
  background-color: gray;
  color: #f5f5f5;
  font-weight: 500;
  font-size: 1.78em;
}

.profileName {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
}

.profileId {
  border: 1px solid rgb(228, 225, 225);
  background-color: white;
  border-radius: 6px;
  margin-top: 20px;
  padding: 10px 20px;
  box-shadow: 0px 0px 5px #a1a4a7;
}

.options {
  font-size: 1.3rem;
  font-weight: 600;
}

.selectOptions {
  margin-top: 15px;
  margin-bottom: 15px;
}

.select-label-container {
  display: flex;
  flex-direction: column;
}

.select-label {
  font-size: 0.75rem;
  color: #777;
  margin-bottom: 4px;
}

.select-selected-value {
  font-size: 1rem;
}

.select-option-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-option-checkmark {
  color: #0070ba;
}

.nationality,
.merchant,
.national {
  margin-bottom: 20px;
  margin-top: 20px;
}

.nationality,
.merchant {
  line-height: 0.8;
}

.features,
.closeAccount {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgb(212, 208, 208);
  border-radius: 7px;
  padding: 20px 10px;
}

.features:hover,
.closeAccount:hover {
  box-shadow: 0px 0px 5px #a1a4a7;
  cursor: pointer;
}

.features>.icon,
.closeAccount>.icon {
  font-size: 24px;
  margin-right: 10px;
  color: #2c2e2f;
  border: 1px solid rgb(250, 243, 243);
  padding: 6px;
  border-radius: 50%;
  background-color: rgb(250, 243, 243);
  margin-top: auto;
  margin-bottom: auto;
}

.addressEmail,
.addressNumber,
.addressAddress {
  margin-bottom: 20px;
}

.addressEmail,
.addressNumber,
.addressAddress {
  border: 1px solid rgb(228, 225, 225);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #a1a4a7;
  padding: 10px 20px;
}

.addEmail1 {
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 0.75em;
}

.mobilePrimary {
  display: flex;
}

.mobileNumber {
  margin: 0;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  margin-top: auto;
}

.addEmail,
.addPhoneNumbers,
.addAddress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.primaryAddress {
  margin-top: 10px;
  margin-top: auto;
}

.place {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.primaryEmailP {
  margin: 0;
  color: #0070ba;
  font-weight: 400;
  background-color: rgb(209, 209, 236);
  width: 70px;
  text-align: center;
  padding: 2px 4px;
  border-radius: 20px;
  margin-top: auto;
}

.myNumber,
.address1>p,
.changeEmailP {
  font-weight: 600;
  margin-top: auto;
}

.emailDesc {
  color: gray;
  font-size: 0.9rem;
  margin-top: auto;
}

.changeEmail,
.phoneNumber {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.address1 {
  line-height: 0.6;
  margin-top: 0.75em;
}

.kenyan {
  font-size: 0.9rem;
  margin: 0;
  color: gray;
}

.kenyan1 {
  font-weight: 500;
 margin-top: 0.78em;
}
.idNumber {
  display: flex;
  justify-content: space-between;
  margin: 0;
  line-height: 0.1;
}

.edit,
.passport,
.upgrade,
.addEmail2,
.changeEmailL {
  margin-top: auto;
  margin-bottom: auto;
  text-decoration: none;
  color: #0070ba;
  font-weight: 600;
  font-size: 0.9rem;
}

.passport {
  font-size: 1.1rem;
}

.edit:hover,
.passport:hover,
.upgrade:hover,
.addEmail2:hover,
.changeEmailL:hover {
  text-decoration: underline;
}

.closeAccount>p,
.unlock {
  font-weight: 600;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 992px) {
  .main {
    padding: 0;
    display: block;
  }

  .profile,
  .address {
    width: 100%;
  }

  .profileDetails,
  .profileId,
  .addressEmail,
  .addressNumber,
  .addressAddress {
    margin-bottom: 8px;
  }

  .profileId {
    margin-top: 0;
  }
}

@media (min-width: 993px) {
  .main {
    padding: 20px 5%;
  }
}

@media (min-width: 1280px) {
  .main {
    padding: 20px 10%;
  }
}

@media (min-width: 1536px) {
  .main {
    padding: 20px 20%;
  }
}
