.commonQ {
  width: 75%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.commonQ>.commonQP {
  font-size: 1.3rem;
  font-weight: 500;
  color: black;
  line-height: 1rem;
}

.myContainer {
  margin-top: 10px;
}

.myContainerDiv {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 11px 0;
}

.myContainerDiv>.containerP {
  font-size: 1.2rem;
  font-weight: 400;
  color: black;
  margin-top: auto;
  margin-bottom: auto;
}

.myContainerDiv>.containerIcon {
  font-size: 1.3rem;
  margin-top: auto;
  margin-bottom: auto;
}

.myContainerActive {
  border: 2px solid #0070ba;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #0079c1;
  margin-bottom: 15px;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 20px;
}

.questionLinks {
  color: #0070ba;
  font-weight: 600;
}

.questionLinks:hover {
  color: #0070ba;
}

.subtopic {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .commonQ {
    width: 100%;
  }
}