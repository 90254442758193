.sendMoney2 {
  margin-top: 9rem;
  left: 0;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.payment2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
}

.payment2 > P {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

#multiple {
  font-size: 13px;
  font-weight: 500;
}

.requestInput {
  width: 100%;
  border: none;
  border-radius: 1.6rem;
  padding: 0.8rem 1.3rem;
  font-size: 1.1rem;
  background-color: white;
  border: 1px solid rgb(226, 219, 219);
}

.requestInput:focus {
  outline: none;
  border: 1px solid #046096;
  box-shadow: 0px 0px 5px #0079c1;
}

.users {
  display: flex;
  justify-content: end;
}

#btn2 {
  width: 17%;
  padding: 1% 2%;
  border-radius: 20px;
}

.moreWays2 {
  gap: 20px;
  width: 28%;
}

.moreWays2 > p {
  font-size: 20px;
  font-weight: 500;
}

.invoice2 {
  display: flex;
}

.invoice2:hover {
  color: black;
}

.waysIcon2 {
  height: 25px;
  width: 25px;
  background-color: rgb(240, 235, 235);
  border-radius: 50%;
  padding: 3px;
  color: #0070ba;
  margin-right: 5px;
  margin-top: 2px;
}

.div2 {
  gap: 0;
}

.div2 > .p3 {
  margin-bottom: 0;
  font-weight: 600;
}

.div2 > .p6 {
  font-size: 12px;
  font-weight: 300;
  margin-top: 0;
  line-height: 13px;
}

.selectedContact {
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 4px;
  margin-right: 4px;
}

.contactName {
  margin-right: 8px;
}

.clearIcon {
  cursor: pointer;
}


.contactAbc {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px; 
  transition: background-color 0.3s ease;
  background-color: #f5f5f5; 
  z-index: 10;
}

.contactAbc:hover {
  background-color: #ebebeb;
}

.selectedAbc {
  background-color: #b8d8d8; 
  z-index: 20; 
}

.userNotFound {
  color: red;
  font-weight: bold;
  z-index: 1; 
}
@media screen and (max-width: 1024px) {
  .sendMoney2 {
    flex-direction: column;
    gap: 30px;
    width: 90%;
  }

  .payment2, .moreWays2 {
    width: 100%;
  }

  .payment2 > #btn2 {
    width: 24%;
    padding: 1% 2%;
    border-radius: 20px;
  }
}