.buy {
  margin-top: 3rem;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buyDiv1 {
  border: 1px solid rgb(190, 183, 183);
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
}

.forUsername {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.forUsername1 {
  height: 40px;
  width: 40px;
  border: 1px solid lightgray;
  border-radius: 50%;
  padding: 15px;
}

.forUsernameP {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 600;
  font-size: 16px;
}

.dollaSign {
  width: 40%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.currencyContainer {
  display: flex;
  align-items: center;
}

.currencyDropdownContainer {
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.currencyDropdown {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  padding: 5px;
  margin: 0;
}
.errorMessage {
  background-color: #ffdddd;
  border: 1px solid #ff0000;
  border-radius: 4px;
  color: #ff0000;
  font-size: 14px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}

.errorMessage::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 1px dashed #ff0000;
  border-radius: 6px;
}

.animation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Roboto', sans-serif; 
  height: auto;
  margin-top: 1rem;
}

.transfer-details {
  text-align: left;
  max-width: 400px;
  margin-top: 20px;
  margin-left: 3%;
}

.transfer-details h4 {
  font-weight: 1000; /* Roboto bold */
  margin-bottom: 20px;
}

.transfer-details p {
  font-weight: 400; /* Roboto regular */
}

.download-receipt {
  background-color: #0070ba;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-weight: 700;
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

.download-receipt:hover {
  box-shadow: 0 4px 10px rgba(0, 112, 186, 0.8);
}

.dSign {
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
}

.dropdownIcon {
  margin-left: 5px;
  font-size: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.finishRequestFromInput {
  width: 50%;
  max-width: 90%;
  font-size: 26px;
  font-weight: 500;
  border: none;
  outline: none;
  padding: 0;
}

.usCurrency {
  font-weight: 600;
  color: #0070ba;
}

.usCurrency:hover {
  color: #0070ba;
}

.finishRequestFromInput1 {
  padding: 0;
  border: none;
  outline: none;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.eligible {
  margin-bottom: 0;
}

.eligibleP1,
.eligibleP2 {
  font-size: 13px;
  text-align: center;
  font-weight: 500;
}

.eligibleP1 > .buyer,
.eligibleP2 > .buyer {
  color: #0070ba;
}

.btns2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.continue1 {
  width: 30%;
  padding: 1% 3%;
  border-radius: 20px;
}

.continue2 {
  width: 30%;
  border: none;
  color: #0070ba;
  text-align: center;
}

.continue2:hover {
  color: #0070ba;
}

.currencyDropdown {
  width: 50%;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  padding: 5px;
  margin-top: 10px;
}

@media screen and (max-width: 769px) {
  .buy {
    width: 90%;
  }

  .forUsername1 {
    height: 30px;
    width: 30px;
    padding: 3px;
  }
}
