.modal3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000  !important;
}

.modal-content3 {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 80%;
  max-width: 600px;
  height: 100vh;
}

.closeContainer3 {
  width: 100%;
  display: flex;
  justify-content: end;
}

.password3 {
  width: 60%;
  margin: 20% auto;
}

.close3 {
  top: 10px;
  right: 15px;
  font-size: 2rem;
  color: #777272;
  cursor: pointer;
  border: none;
  background-color: white;
}

.close3:hover {
  text-decoration: underline;
}

.passwordHeader3 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
}

.addition3 {
  text-align: center;
  color: #5c5a5a;
  font-size: 0.9rem;
}

.fieldContainer select,
.fieldContainer input {
  width: 100%;
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 5px;
}

.fieldContainer select:focus,
.fieldContainer input:focus {
  outline: 2px solid #0070ba;
}

.questionGroup,
.questionGroup1 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.questionGroup {
  border-bottom: 1px solid lightgray;
}

.currentPasswordDiv1 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.passwordField5 {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  font-size: 1rem;
  border: 2px solid lightgray;
  box-sizing: border-box;
}

.passwordField5:focus {
  outline: none;
  border-color: #0070ba;
  box-shadow: 0 0 5px #0070ba;
}

.passwordField5.error {
  border-color: red;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.passwordError5 {
  margin: 0;
  border: 2px solid red;
  padding: 4px;
  border-top: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(245, 106, 106, 0.1);
  border-radius: 0 0 5px 5px;
  /* Changed to remove border-radius on top */
}

.btn3 {
  width: 100%;
  padding: 12px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid #0070ba;
  background-color: #0070ba;
  color: white;
  font-weight: 500;
  box-sizing: border-box;
}

.btn3:hover {
  background-color: #0070ba;
}

.logoContainer3 {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 641px) {
  .modal-content3 {
    width: 100%;
  }

  .password3 {
    width: 100%;
  }
}
