/* CheckmarkAnimation.css */
.checkmark-container {
  width: 100px;
  height: 100px;
  position: relative;
}

.checkmark {
  width: 100%;
  height: 100%;
  stroke-width: 2;
  stroke: #27ae60;
  stroke-miterlimit: 10;
  fill: none;
}

.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #27ae60;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards,
    scale 0.1s ease 1.3s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

.step-tab-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh; /* Set the desired height of the container */
}

.d-flex.justify-content-center.flex-column.align-items-center.text-center {
  text-align: center;
}
