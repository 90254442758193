/* Custom Container Styles */

.custom-padding {
    padding: 4vw !important;
}

/* Custom Heading Styles */
.custom-heading {
    font-size: 2.5vw;
    margin-top: 2vw;
    margin-bottom: 4vw;
}

/* Custom Row Styles */
.custom-margin-bottom {
    margin-bottom: 4vw !important;
}

/* Icon Circle Background */
.icon-circle-bg {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #49b5f85d;  
}

/* Custom Icon Styles */
.custom-icon-circle {
    width: 3vw;
    height: 3vw;
}

.custom-icon-size {
    font-size: 1vw;
}

/* Default font sizes */
.card-title-font {
    font-size: 1.25rem;
    font-weight: 500;  
}

.card-text-font {
    font-size: 1rem;
    font-weight: 400;  /* Between normal and bold */
}

.fixed-size-button {
    width: 80px; /* or whatever width you find suitable */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



/* Small devices */
@media (max-width: 575.98px) {
    .card-title-font {
        font-size: 0.8rem;
    }
    
    .card-text-font {
        font-size: 0.875rem;
    }

    .custom-icon-circle {
        width: 6vw;
        height: 6vw;
    }

    .custom-icon-size {
        font-size: 3vw;
    }
}

/* Medium devices */
@media (min-width: 768px) and (max-width: 991.98px) {
    .card-title-font {
        font-size: 1.25rem;
    }
    
    .card-text-font {
        font-size: 1rem;
    }

    .custom-icon-size {
        font-size: 24px;
    }

    .custom-icon-circle {
        width: 40px;
        height: 40px;
    }

    .custom-heading {
        font-size: 24px;
    }
}

/* For large devices */
@media (min-width: 992px) {
    .custom-padding {
        padding: 24px !important;
    }
    .custom-margin-bottom {
        margin-bottom: 24px !important;
    }
}
