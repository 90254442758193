html,
body {
  overflow-x: hidden;
  max-width: 100%;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #0070ba;
  padding: 20px 20%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: fixed;
  top: 0; 
  left: 0;
  width: 100%;
  z-index: 9999 !important;
}
.header-container {
  margin-bottom: 2em;
}

.ravel-logo {
 
  border: 1px solid white;
  border-radius: 50%;
  width: 4%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0;
  cursor: pointer;
}

.sideMenu {
  color: #ffffff;
  display: none;
  font-size: 20px;
  z-index: 9999 !important;
}

.dashboardheader {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.navLinks,
.logOut {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navLink {
  margin: 0 4px;
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  border: 1px solid #0070ba;
  border-radius: 24px;
}

.navLink:hover {
  color: #a1c7f1;
  border: 1px solid #ffffff;
  border-radius: 24px;
  text-decoration: none;
}

.settings {
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  margin: auto 10px;
}

.settings1 {
  margin-top: auto;
  margin-bottom: 0;
  padding: 0;
  text-decoration: none;
}

.logout {
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  margin: auto 0 auto 10px;
}

.popUp {
  left: 0;
  padding: 4% 0;
  height: 100%;
  width: 60%;
  background-color: #0070ba;
  top: 0;
  position: absolute;
  z-index: 9998;
}

.popUp1 {
  display: flex;
  justify-content: space-between;
  padding-left: 4%;
  padding-right: 4%;
}

.popUpP {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  padding: 10px 6%;
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%; 
}

.popUp2 {
  color: #ffffff;
  text-decoration: none;
}

.popLinks {
  display: grid;
  text-align: start;
}

.popLink {
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  padding: 10px 6%;
  border-top: 1px solid #0070ba;
  border-bottom: 1px solid #0070ba;
}

.popLink:hover {
  color: #a1c7f1;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .navbar {
    padding: 3%;
    overflow-x: hidden;
    z-index: 9999 !important;
  }

  .ravel-logo {
    width: 5.2%;
    display: none;
  }

  .sideMenu {
    display: block;
    z-index: 9999 !important;
  }

  .dashboardheader {
    display: none;
  }

  #logo1 {
    display: block;
  }

  .settings1,
  .logout {
    display: none;
  }
}

@media (min-width: 769px) {
  .navbar {
    justify-content: center;
    z-index: 9999 !important;
  }

  .dashboard {
    margin-right: auto;
    margin-left: auto;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .navbar {
    padding: 3%;
    overflow-x: hidden;
    z-index: 9999 !important;
    justify-content: space-between; 
  }

  .sideMenu {
    display: block;
    z-index: 9999 !important;
  }

  .navLinks {
    display: none;
  }

  #logo1 {
    display: block;
  }

  .settings1,
  .logout {
    display: block;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1070px) {
  .navbar {
    padding: 20px 20%;
    z-index: 9999 !important;
    justify-content: space-between; 
  }

  .sideMenu {
    display: none;
  }

  .navLinks {
    display: flex;
    justify-content: center; 
  }

  .settings1,
  .logout {
    display: block;
  }
}
