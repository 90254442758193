.modalUpdateBalance {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(228, 224, 224);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000 !important;
  }
  
  .modal-contentUpdateBalance {
    background-color: white;
    padding: 20px;
  
    width: 80%;
    max-width: 600px;
    height: 100vh;
  }
  
  .closeContainerUpdateBalance {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  
  .closeUpdateBalance{
    font-size: 25px;
    color: #777272;
    cursor: pointer;
    border: none;
    background-color: white;
  }
  
  .closeUpdateBalance:hover {
    color: #0070ba;
  }
  
  .updateBalancePa{
    font-size: 25px;
  font-weight: 400;
  color: black;
  margin: 10px 0;
  text-align: center;
  line-height: 1.2;
  text-decoration: none;
}
  .updateBalancePa2{
    text-align: center;
  }
  .modalContentUpdateBalanceDiv {
    width: 100%;
    margin: auto;
    padding: 20px; /* add some padding to create a gap between the content and the modal edges */
  }
  
  
  .btnUpdateBalance {
    width: 100%;
    padding: 8px;
    border-radius: 30px;
    cursor: pointer;
    border: 1px solid #0070ba;
    background-color: #0070ba;
    color: white;
    font-weight: 500;
    box-sizing: border-box;
  }
  
  .btnUpdateBalance:hover {
    background-color: #0070ba;
    text-decoration: underline;
  }
  
  @media (max-width: 641px) {
    .modal-contentUpdateBalance {
      width: 100%;
    }
  
    .modalContentUpdateBalanceDiv {
      width: 90%;
      padding: 10px;
    }
  }
  
  @media (max-width: 768px) { /* adjust the breakpoint as per your target devices */
    .modalContentUpdateBalanceDiv {
      padding: 10px; /* adjust the padding for smaller devices */
    }
  }