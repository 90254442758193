.pyplDepositSection {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  gap: 20px;
}

.introHeader {
  color: #0070ba;
  margin-bottom: 1rem;
  text-align: center;
}

.introHeader h1 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}

.depositWrapperSplitter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.depositContainer .balance {
  border-bottom: 1px solid #333 !important;
  margin-bottom: 1.5em;
  width: 100%;
  margin-top: 1em;
}

.depositContainer p {
  width: 100%;
  font-size: 14px;
  margin-bottom: 1em;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.inputContainer input {
  border-radius: 5px;
  padding: 0.5em;
  background-color: white;
  border: 3px solid rgb(226, 219, 219);
  width: 100%;
}

.inputContainer input:focus {
  border-right: 3px !important;
  outline: none;
  border: 1px solid #046096;
  box-shadow: 0px 0px 5px #0079c1;
}

.inputContainer select {
  appearance: none;
  padding: 0.5em;
  border: 3px solid #cacbcb;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  background-color: #cacbcb;
}


.buttonContainer button {
  background: #0070ba;
  color: #fff;
  padding: 0.5em;
  border-radius: 30px;
  width: 100%;
  border: 1px solid #0070ba;
}

.importantInfo {
  text-align: center;
  color: #0070ba;
  padding-left: 10em;
  margin-top: 1.5em;
  padding-bottom: 3em;
  border-bottom: 1px solid #cacbcb;
}

.errorMessage {
  background-color: #ffdddd;
  border: 1px solid #ff0000;
  border-radius: 4px;
  color: #ff0000;
  font-size: 14px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}

.errorMessage::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 1px dashed #ff0000;
  border-radius: 6px;
}

.animationcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  height: auto;
  margin-top: 1rem;
}

.transferdetails p {
  font-weight: 400;
}

.downloadreceipt {
  background-color: #0070ba;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-weight: 700;
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

.downloadreceipt:hover {
  box-shadow: 0 4px 10px rgba(0, 112, 186, 0.8);
}

.centeredContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.centeredContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centeredButton {
  margin-top: 20px;
  text-align: center;
}

.styles.currencyInput {
  /* Add your styles here */
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
}

.stepContent {
  width: 100%;
}

.stepContent p {
  font-size: 16px;
  margin-bottom: 1em;
}

.stepContent select {
  margin-bottom: 1em;
  font-size: 16px;
  padding: 12px; 
  width: 100%;
  border-radius: 4px;
  background-color: white;
  border: 3px solid rgb(226, 219, 219);
}

.stepContent select:focus {
  border-right: 3px solid; 
  outline: none;
  border: 1px solid #046096;
  box-shadow: 0px 0px 5px #0079c1;
}

.stepContent button {
  background: #0070ba;
  color: #fff;
  padding: 0.5em;
  border-radius: 30px;
  width: 100%;
  border: 1px solid #0070ba;
}

@media screen and (max-width: 767px) {
  .stepContent select,
  .stepContent button {
    width: 80%;
  }
}

.errorMessage {
  background-color: #ffdddd;
  border: 1px solid #ff0000;
  border-radius: 4px;
  color: #ff0000;
  font-size: 14px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}

.errorMessage::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 1px dashed #ff0000;
  border-radius: 6px;
}

.successMessage {
  background-color: #d4edda;
  border: 1px solid #155724;
  border-radius: 4px;
  color: #155724;
  font-size: 14px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}

.successMessage::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 1px dashed #155724;
  border-radius: 6px;
}

.customSelect {
  margin-bottom: 1em;
  font-size: 16px;
  width: 100%;
  border-radius: 4px;
  background-color: white;
  border: 3px solid rgb(226, 219, 219);

}

.customSelect:focus {
  border-right: 3px solid; 
  outline: none;
  border: 1px solid #046096;
  box-shadow: 0px 0px 5px #0079c1;
}
.tableContainer {
  width: 100%;
  overflow-x: auto; 
  margin-bottom: 2em;
}

.table {
  width: 100%;
  border-collapse: collapse; 
  margin-top: 20px; 
}

.table th,
.table td {
  padding: 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f2f2f2;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end; 
}
.editButton {
  border: none; 
  outline: none; 
  color: #0079c1; 
  background-color: transparent; 
  cursor: pointer; 
}

.editButton:hover {
  color: #0079c1; 
}
.informationText {
  font-size: 0.8em;
  color: #777;
  margin-top: 0.5em;
}
.currencyAmount {
  font-weight: bold;
  font-size: 1em;
  color: #046096; 
  margin-right:0.3em;
}


@media screen and (max-width: 600px) {
  .table th,
  .table td {
    padding: 10px; 
  }
}

@media screen and (max-width: 767px) {
  .svgTransferIllustration {
    display: none;
  }

  .introHeader h1 {
    font-size: 25px;
    line-height: 1.5em;
  }

  .pyplDepositSection {
    width: 90%;
  }

  .depositWrapperSplitter {
    width: 100%;
  }

  .importantInfo {
    padding: 0;
    text-align: left;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .inputContainer input,
  .inputContainer select,
  .customSelect {
    width: 100%;
  }

  .buttonContainer {
    flex-direction: column; 
  }
}

@media (max-width: 767px) {
  .mainDepositContent {
    padding: 0 10px;  
  }
  .buttonContainer button {
    width: 100%;
  }
}

